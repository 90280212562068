import * as React from "react";

import Layout from "../components/Layout";
import SEO from "../components/Seo";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { css } from "@emotion/core";

import Masonry from "react-masonry-css";
import Testimonials from "../components/sections/Testimonials";
import Share from "../components/sections/Share";
import { UniversalLink } from "../components/global";

const GalleryPage = ({ data }) => {
  const photos = data.allFile.edges;
  return (
    <Layout>
      <SEO title={"Gallery"} />
      <section className={"section container mx-auto"}>
        <h1 className={"h1 text-center"}>
          Photos
          <span role={"img"} aria-label={"camera-emoji"}>
            {" "}
            📷{" "}
          </span>
        </h1>

        <Masonry
          breakpointCols={{
            default: 2,
            700: 1,
          }}
          className="gallery-masonry-grid mt-8"
          columnClassName="gallery-masonry-grid_column"
          css={css`
            display: flex;
            width: auto;
            .my-masonry-grid_column {
              padding-left: 30px; /* gutter size */
              background-clip: padding-box;
            }

            .gallery-masonry-grid_column > div {
              /* change div to reference your elements you put in Masonry */
              margin-bottom: 30px;
            }
          `}
        >
          {photos.map(({ node }) => (
            <div key={node.id} className={"w-full p-6 rounded"}>
              <figure className={"shadow-lg"}>
                <Img
                  className={"rounded-t"}
                  fluid={
                    node.childMarkdownRemark.frontmatter.photo.childImageSharp
                      .fluid
                  }
                  alt={
                    node.childMarkdownRemark.frontmatter.caption ||
                    node.childMarkdownRemark.frontmatter.submitted_by
                  }
                />

                <figcaption
                  className={"rounded-b bg-gray-900 text-white px-4 py-2"}
                >
                  {node.childMarkdownRemark.frontmatter.is_caption && (
                    <span className={"block text-sm"}>
                      {node.childMarkdownRemark.frontmatter.caption}
                    </span>
                  )}
                  <span className={"block text-xs text-right"}>
                    Submitted by{" "}
                    {node.childMarkdownRemark.frontmatter.submitted_by}
                  </span>
                </figcaption>
              </figure>
            </div>
          ))}
        </Masonry>
      </section>
      <h2 className={"mt-16 h2 text-center"}>
        A few reasons why we
        <span role={"img"} aria-label={"heart-emoji"}>
          {" "}
          ❤️{" "}
        </span>
        BD...
      </h2>
      <h3 className={"text-center mt-4"}>
        <UniversalLink
          url={"/testimonials"}
          className={"border-b border-primary"}
        >
          View all testimonials
        </UniversalLink>
      </h3>
      <Testimonials />
      <Share />
      <div className={"pb-16"} />
    </Layout>
  );
};

export const galleryPageQuery = graphql`
  query GalleryPageQuery {
    allFile(filter: { relativeDirectory: { eq: "photos" } }) {
      edges {
        node {
          id
          childMarkdownRemark {
            frontmatter {
              caption
              is_caption
              submitted_by
              submitted_via
              photo {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default GalleryPage;
